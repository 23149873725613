import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from "./Container/Context";
import ScrollToTop from "./Pages/ScrollToTop/ScrollToTop";
import GA4React from "ga-4-react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

window.tinyAPIKEY = "j1g23ad6n3ra8f5lbnbe3nldrui85ygcv9zwc1ao74848kmj";
window.user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const root = ReactDOM.createRoot(document.getElementById("root"));
try {
  setTimeout(() => {
    const ga4react = new GA4React("G-M0P9BN8NK5");
    ga4react.initialize().catch((err) => console.error(err));
  }, 8000);
} catch (err) {
  console.error(err);
}

root.render(
  <BrowserRouter>
    <CartProvider>
      <ScrollToTop />
      <App />
    </CartProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();

reportWebVitals();
