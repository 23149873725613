import React from "react";
import { HelmetProvider } from "react-helmet-async";
import TimeoutLogic from "./Pages/Login/TimeoutLogic";
import { Toaster } from "react-hot-toast";

const AllRoutes = React.lazy(() =>
    import("./Route/AllRoutes")
);

function App() {
    const helmetContext = {};
    return (
        <HelmetProvider context={helmetContext}>
            <React.Fragment>
                <React.Suspense fallback={""}>
                    <AllRoutes />
                </React.Suspense>
                <TimeoutLogic />
                <Toaster />
            </React.Fragment>
        </HelmetProvider>
    );
}

export default App;
