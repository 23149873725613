import { useState, useEffect, createContext } from "react";
import CartService from "../Services/CartService";
import MetaService from "../Services/MetaServices/MetaService";
import { matchPath } from "react-router-dom";
import SEO from "../Pages/SEO/SEO";

export const CartContext = createContext();
const { Provider } = CartContext;
const metaService = new MetaService();

export const CartProvider = (props) => {
  const [banner, setBannerImage] = useState();
  const [SearchModal, setSearchModal] = useState(false);
  const cartServe = new CartService();
  const [cartList, setCartList] = useState([]);
  const [itemInCart, setItemInCart] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [percentage, setPercentage] = useState(null);
  const [testResponse, settestResponse] = useState([]);
  const [userName, setUserName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [successCoupon, setSuccessCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [userImage, setUserImage] = useState("");
  const [assessmentCouponDiscount, setAssessmentCouponDiscount] =
    useState(null);
  const [voucherCouponDiscount, setVoucherCouponDiscount] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [discountCouponAmt, setDiscountAmt] = useState(null);
  const [reportQuestion, setReportQuestion] = useState([]);
  const [data, setData] = useState({});
  const [loginData, setLoginData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [open, setOpen] = useState(false);
  const [instruction, setInstruction] = useState(false);
  const [AssessmentCategoryListData, setAssessmentCategoryListData] = useState(
    []
  );
  //FOR SEO
  const [hambergerBtnValue, setHambergerBtn] = useState(false);
  const [documentVisibleFooter, setDocumentVisibleFooter] = useState(false);
  const [documentVisibleCourseBreadcrumb, setDocumentVisibleCourseBreadcrumb] =
    useState(false);
  const [
    documentVisibleBuyTogetherSection,
    setDocumentVisibleBuyTogetherSection,
  ] = useState(false);
  const [documentVisibleCitrixTraining, setDocumentVisibleCitrixTraining] =
    useState(false);
  const [documentVisibleCourseCurriculum, setDocumentVisibleCourseCurriculum] =
    useState(false);
  const [
    documentVisibleAboutCertification,
    setDocumentVisibleAboutCertification,
  ] = useState(false);
  const [documentVisibleCitrixReview, setDocumentVisibleCitrixReview] =
    useState(false);
  const [documentVisibleWhySsdn, setDocumentVisibleWhySsdn] = useState(false);
  const [documentVisibleFaq, setDocumentVisibleFaq] = useState(false);
  const [documentVisibleRelatedCourse, setDocumentVisibleRelatedCourse] =
    useState(false);
  const [documentVisibleCourseBlog, setDocumentVisibleCourseBlog] =
    useState(false);
  const [
    documentVisibleVoucherDetailCard,
    setDocumentVisibleVoucherDetailCard,
  ] = useState(false);
  const [
    documentVisibleVoucherDescription,
    setDocumentVisibleVoucherDescription,
  ] = useState(false);
  const [documentVisibleRelatedVouchers, setDocumentVisibleRelatedVouchers] =
    useState(false);
  // END

  //E-Learning SEO
  const [documentVisibleOverviewLeft, setDocumentVisibleOverviewLeft] =
    useState(false);
  const [documentVisibleOverviewCard, setDocumentVisibleOverviewCard] =
    useState(false);
  const [documentVisibleImageVideo, setDocumentVisibleImageVideo] =
    useState(false);
  const [documentVisibleAnalytics, setDocumentVisibleAnalytics] =
    useState(false);
  const [documentVisibleReviewList, setDocumentVisibleReviewList] =
    useState(false);
  const [
    documentVisibleELearningRelatedCourse,
    setDocumentVisibleELearningRelatedCourse,
  ] = useState(false);

  //META
  const [meta, setMeta] = useState({});
  const [isMeta, setIsMeta] = useState(false);

  //END E-Learning SEO
  let guest_user_id = localStorage.getItem("custumer_id");

  const setAssessmentCategoryListApiData = (assessmentData) => {
    localStorage.setItem("assessment", JSON.stringify(assessmentData));
  };

  const setOrderPaymentData = (data) => {
    localStorage.setItem("payment", JSON.stringify(data));
  };

  window.BeforeUnloadEvent = function () {
    sessionStorage.removeItem("coupon");
  };

  const gettingCartItemslist = async () => {
    let activity = {};
    if (guest_user_id) {
      activity["guest_user_id"] = guest_user_id;
    }
    if (sessionStorage.getItem("coupon")) {
      activity["coupon_code"] = sessionStorage.getItem("coupon");
    }
    if (guest_user_id || window.user?.data?.auth_token) {
      try {
        let response = await cartServe.gettingCartList(activity);
        setPercentage(response);
        setCartList(response.data);
        setCoupon(response.coupon_code);
        sessionStorage.setItem("message", response.coupon_message);
        setCouponMessage(response.coupon_message);
        setSuccessCoupon(response.coupon_status);
        setDiscountAmt(response.coupon_discount);
        const calTotalAmount =
          response?.data &&
          response?.data.reduce((accumulator, v) => {
            return accumulator + v.quantity * v.assessment?.payable_price;
          }, 0);
        setTotalAmount(calTotalAmount);
        const totalQuantity =
          response.data &&
          response.data.reduce((accumulator, v) => {
            return accumulator + parseInt(v.quantity);
          }, 0);
        setItemInCart(totalQuantity);
      } catch (err) {
        throw err;
      }
    }
  };

  // const getbannerImage = async () => {
  //   try {
  //     let response = await metaService.service("banner-image");
  //     if (response.status === "success") {
  //       setBannerImage(response.data?.page_description);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const countryCodeList = async () => {
    try {
      const response = await metaService.getCountryCode();
      if (response.status == "success") {
        // setCountryCode(response.data);
        localStorage.setItem(
          "country_code_list",
          JSON.stringify(response.data)
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const getmetaData = async (page) => {
    try {
      setIsMeta(true);
      let response = await metaService.getMetadetail(page);
      if (response.status === "success") {
        setMeta(response.data);
      }
    } catch (err) {
      throw err;
    }
  };
  const geyMetaDetailPage = async (type, id) => {
    try {
      let response = await metaService.getDetailPageMeta(type, id);
      if (response.status === "success") {
        if (response.data) {
          setIsMeta(true);
          setMeta(response.data);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    var pathname = window.location.pathname;
    var params_array = pathname.split("/");
    var slug = params_array.pop();
    if (matchPath({ path: "/404" }, pathname)) {
      getmetaData("404");
    } else if (matchPath({ path: "/privacy-policy" }, pathname)) {
      getmetaData("privacy-policy");
    } else if (matchPath({ path: "/terms-and-conditions" }, pathname)) {
      getmetaData("term-conditions");
    } else if (matchPath({ path: "/services" }, pathname)) {
      getmetaData("service");
    } else if (matchPath({ path: "/about-us" }, pathname)) {
      getmetaData("about");
    } else if (matchPath({ path: "/testimonials" }, pathname)) {
      getmetaData("testimonials");
    } else if (matchPath({ path: "/upcoming-batch" }, pathname)) {
      getmetaData("upcoming-batches");
    } else if (matchPath({ path: "/upcoming-events" }, pathname)) {
      getmetaData("event");
    } else if (matchPath({ path: "/event-all" }, pathname)) {
      getmetaData("event");
    } else if (matchPath({ path: "/event/:id" }, pathname)) {
      //event
      geyMetaDetailPage("event", slug);
    } else if (matchPath({ path: "/franchise" }, pathname)) {
      getmetaData("franchise");
    } else if (
      matchPath({ path: "/it-and-software-certification-courses" }, pathname)
    ) {
      getmetaData("course");
    } else if (matchPath({ path: "/:id" }, pathname)) {
      //course_category
      geyMetaDetailPage("course_category", slug);
    } else if (matchPath({ path: "/:category_slug/:id" }, pathname)) {
      //course
      geyMetaDetailPage("course", slug);
    } else if (matchPath({ path: "/e-learning" }, pathname)) {
      getmetaData("e-learning");
    } else if (matchPath({ path: "/e-learning/:id" }, pathname)) {
      //e_learning
      geyMetaDetailPage("e_learning", slug);
    } else if (matchPath({ path: "/international-students" }, pathname)) {
      setIsMeta(true);
      getmetaData("international");
    } else if (matchPath({ path: "/contact-us" }, pathname)) {
      getmetaData("contact-us");
    } else if (matchPath({ path: "/assessment" }, pathname)) {
      getmetaData("assessment");
    } else if (matchPath({ path: "/assessment/:id" }, pathname)) {
      //assessment_category
      geyMetaDetailPage("assessment_category", slug);
    } else if (
      matchPath({ path: "/assessment/:category_slug/:id" }, pathname)
    ) {
      //assessment
      geyMetaDetailPage("assessment", slug);
    } else if (matchPath({ path: "/cart" }, pathname)) {
      getmetaData("cart");
    } else if (matchPath({ path: "/Checkout" }, pathname)) {
      getmetaData("cart");
    } else if (matchPath({ path: "/vouchers" }, pathname)) {
      getmetaData("voucher");
    } else if (matchPath({ path: "/vouchers/:slug" }, pathname)) {
      //voucher_category
      geyMetaDetailPage("voucher_category", slug);
    } else if (matchPath({ path: "/vouchers/:category_slug/:id" }, pathname)) {
      //voucher
      geyMetaDetailPage("voucher", slug);
    } else if (matchPath({ path: "/payment" }, pathname)) {
      getmetaData("payment");
    } else if (matchPath({ path: "/" }, pathname)) {
      getmetaData("home");
    }
  }, [window.location]);

  useEffect(() => {
    const name = localStorage.getItem("username");
    const image = localStorage.getItem("userimage");

    if (guest_user_id) {
    } else {
      guest_user_id = localStorage.setItem(
        "custumer_id",
        Math.round(Math.random() * 1000000)
      );
    }
    setUserName(name);
    setUserImage(image);
    // getbannerImage();
    if (!localStorage.getItem("country_code_list")) {
      countryCodeList();
    }
  }, [guest_user_id]);

  useEffect(() => {
    gettingCartItemslist();

    return () => {
      sessionStorage.removeItem("coupon");
    };
  }, [itemInCart, guest_user_id, window.user?.data?.auth_token]);

  return (
    <Provider
      value={{
        cartData: [cartList, setCartList],
        numberInCart: [itemInCart, setItemInCart],
        gettingCartItemslist: gettingCartItemslist,
        totalPrice: [totalAmount, setTotalAmount],
        coupon_code: [coupon, setCoupon],
        success: [successCoupon, setSuccessCoupon],
        couponAmount: [discountCouponAmt, setDiscountAmt],
        percentages: [percentage, setPercentage],
        testResult: [testResponse, settestResponse],
        modal: [modalOpen, setModalOpen],
        pop: [open, setOpen],
        message: [couponMessage, setCouponMessage],
        reported: [reportQuestion, setReportQuestion],
        dataValue: [data, setData],
        login: [loginData, setLoginData],
        assessmentDiscount: [
          assessmentCouponDiscount,
          setAssessmentCouponDiscount,
        ],
        user: [userName, setUserName],
        search: [SearchModal, setSearchModal],
        image: [userImage, setUserImage],
        voucherDiscount: [voucherCouponDiscount, setVoucherCouponDiscount],
        instructionPop: [instruction, setInstruction],
        orderPayment: [paymentData, setOrderPaymentData],
        AssessmentCategory: [
          AssessmentCategoryListData,
          setAssessmentCategoryListApiData,
        ],
        bannery: [banner, setBannerImage],
        // SEO
        hambergerBtn: [hambergerBtnValue, setHambergerBtn],
        dvFooter: [documentVisibleFooter, setDocumentVisibleFooter],
        dvCourseBreadcrumb: [
          documentVisibleCourseBreadcrumb,
          setDocumentVisibleCourseBreadcrumb,
        ],
        dvBuyTogetherSection: [
          documentVisibleBuyTogetherSection,
          setDocumentVisibleBuyTogetherSection,
        ],
        dvCitrixTraining: [
          documentVisibleCitrixTraining,
          setDocumentVisibleCitrixTraining,
        ],
        dvCourseCurriculum: [
          documentVisibleCourseCurriculum,
          setDocumentVisibleCourseCurriculum,
        ],
        dvAboutCertification: [
          documentVisibleAboutCertification,
          setDocumentVisibleAboutCertification,
        ],
        dvCitrixReview: [
          documentVisibleCitrixReview,
          setDocumentVisibleCitrixReview,
        ],
        dvWhySsdn: [documentVisibleWhySsdn, setDocumentVisibleWhySsdn],
        dvFaq: [documentVisibleFaq, setDocumentVisibleFaq],
        dvRelatedCourse: [
          documentVisibleRelatedCourse,
          setDocumentVisibleRelatedCourse,
        ],
        dvCourseBlog: [documentVisibleCourseBlog, setDocumentVisibleCourseBlog],
        dvVoucherDetailCard: [
          documentVisibleVoucherDetailCard,
          setDocumentVisibleVoucherDetailCard,
        ],
        dvVoucherDescription: [
          documentVisibleVoucherDescription,
          setDocumentVisibleVoucherDescription,
        ],
        dvRelatedVouchers: [
          documentVisibleRelatedVouchers,
          setDocumentVisibleRelatedVouchers,
        ],

        // END SEO

        eLCOverviewLeft: [
          documentVisibleOverviewLeft,
          setDocumentVisibleOverviewLeft,
        ],
        eLCOverviewCard: [
          documentVisibleOverviewCard,
          setDocumentVisibleOverviewCard,
        ],
        eLCOImageVideo: [
          documentVisibleImageVideo,
          setDocumentVisibleImageVideo,
        ],
        eLCAnalytics: [documentVisibleAnalytics, setDocumentVisibleAnalytics],
        eLCReviewList: [
          documentVisibleReviewList,
          setDocumentVisibleReviewList,
        ],
        eLCRelatedCourse: [
          documentVisibleELearningRelatedCourse,
          setDocumentVisibleELearningRelatedCourse,
        ],
      }}
    >
      {isMeta && (
        <SEO
          meta_title={meta?.meta_title}
          meta_description={meta?.meta_description}
          meta_keyword={meta?.meta_keyword}
          breacrumb={meta?.breadcrumb}
          og_title={meta?.og_title}
          og_url={meta?.og_url}
          og_description={meta?.og_description}
          og_image={meta?.og_image}
        />
      )}
      {props.children}
    </Provider>
  );
};
