import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SEO({
  meta_title,
  meta_description,
  meta_keyword,
  breacrumb,
  ogTitle,
  OgImage,
  OgUrl,
  OgType,
  OgDescription,
}) {
  useEffect(() => {
    if (meta_title) document.title = meta_title;
  });

  useEffect(() => {
    function createScripTag(value) {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/partytown";
      scriptElement.textContent = value; 
      document.head.appendChild(scriptElement);
      return scriptElement;
    }

    function myFunction(string) {
      if (string) {
        const result = string.split(`</script>`);
        if (result.length > 0) {
          const create_script = result.map((item) => {
            var start_point = item.indexOf("<script");
            var end_point = item.indexOf(">");
            end_point = start_point == 0 ? end_point + 1 : end_point;
            var plain_text = item.substr(start_point, end_point);
            var script = item.replace(plain_text, "");
            if (script) {
              return createScripTag(script);
            }
          });
        }
      }
    }

    myFunction(breacrumb);

    return () => {};
  }, [breacrumb]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{meta_title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="Revisit-after" content="7 Days" />
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keyword} />
        <meta property="og:title" content={ogTitle || meta_title} />
        <meta
          property="og:image"
          content={OgImage || `/assets/images/logo/ssdn-new-logo-200x200.png`}
        />
        <meta property="og:url" content={OgUrl || window.location.href} />
        <meta property="og:type" content={OgType ? OgType : "website"} />
        <meta
          property="og:description"
          content={OgDescription || meta_description}
        />
      </Helmet>
    </HelmetProvider>
  );
}
